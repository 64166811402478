import { useApp } from "../../../../services/app-context";
import { secondsToMinute } from "../../utils";
import { TranscriptView } from "../TranscriptView";

function ManagerComments() {
  return (
    <div className="relative bg-white pt-11 rounded-md">
      <div className="absolute top-0 left-0 w-full border border-[#DEDEDE] bg-[#B4B4B421] rounded-[7px] h-11 flex items-center px-4 text-[#242424] text-[17px] leading-[20.5px]">
        Manager Comments and Feedback
      </div>
      <div className="bg-white rounded-md p-4">
        Excellent job on your role play! You demonstrated strong product
        knowledge, maintained compliance, and communicated clearly. A few things
        to work on as you continue to practice... Dynamic Openings - This will
        drive the conversation. Use open-ended impact questions to peak the HCPs
        interest. Closing - Ensure to ask for actionable next steps. This will
        create a meaningful follow up message.
      </div>
    </div>
  );
}

export function SessionRecap({ report, currentIndex, setCurrentIndex }) {
  console.log(report?.videoUrl);
  const { tenantProfile } = useApp();

  console.log(tenantProfile);
  const isCertificationMode = !!tenantProfile?.subscriptions?.certifications;
  return (
    <div
      className={`flex flex-col gap-4 ${
        isCertificationMode ? "lg:flex-row" : ""
      }`}
    >
      <div
        className={`w-full flex flex-col gap-4 ${
          isCertificationMode ? "lg:w-[650px]" : ""
        }`}
      >
        <div className="mt-7 flex items-center gap-2 overflow-auto flex-wrap">
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Duration:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              {secondsToMinute(report.duration)} mins
            </div>
          </div>
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Sentiment:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              Positive 33%
            </div>
          </div>
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Filler:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              18%
            </div>
          </div>
          <div className="w-fit px-4 py-1.5 rounded-3xl border border-[#DDD] justify-start items-center gap-1.5 flex">
            <div className="text-black/60 text-[15px]">Questions:</div>
            <div className="text-black text-[15px] font-medium whitespace-nowrap">
              7%
            </div>
          </div>
        </div>
        {tenantProfile?.subscriptions?.certifications && (
          <>
            <div className="h-[478px] w-full lg:w-[650px] rounded-lg mt-4">
              <video
                className="w-full h-full object-cover rounded-lg"
                src={report?.videoUrl}
                controls
                playsInline
                onError={(e) => {
                  console.log(e);
                }}
              />
            </div>
            <ManagerComments />
          </>
        )}
      </div>
      <div className="flex-1 w-full">
        <TranscriptView
          conversations={report?.conversations || 0}
          currentIndex={currentIndex}
        />
      </div>
    </div>
  );
}
