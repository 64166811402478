import { useEffect } from "react";
import { MessageView } from "./Message";
import type { Conversation } from "../ReportScreen";

export function TranscriptView({
  conversations,
  currentIndex,
}: {
  conversations: Conversation[];
  currentIndex?: number;
}) {
  useEffect(() => {
    if (currentIndex === undefined) return;
    const message = conversations[currentIndex];
    if (message) {
      // scroll to message
      const el = document.getElementById(message.id);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [conversations, currentIndex]);
  return (
    <div className="bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] p-6 rounded-[14px]">
      <div className="font-bold text-xl text-black mb-5">Transcript</div>
      <div className="min-h-[calc(100vh-150px)] h-[20vh] overflow-auto flex flex-col gap-2">
        {conversations.map((msg, i) => (
          <MessageView
            key={msg.id}
            message={msg}
            isMe={msg.isUserMessage}
            isCurrent={i === currentIndex}
          />
        ))}
      </div>
    </div>
  );
}
