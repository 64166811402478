import { processingMessages } from "../../constant";
import AWS from "aws-sdk";
import { ACCESS_KEY_ID, REGION, SECRET_ACCESS_KEY } from "../../env";

/**
 * pitch: 1.1
 * lang: "en-US"
 * volume: 0.5
 * voice: "Google UK English Male"
 * @param text
 */

export function speak(text: string, shouldCancel = false) {
  // Create a SpeechSynthesisUtterance
  const utterance = new SpeechSynthesisUtterance(text);

  // Select a voice
  const voices = speechSynthesis.getVoices();
  const voice = voices.find(
    (voice) => voice.name === "Google UK English Female"
  );
  if (voice) {
    utterance.voice = voice;
  }
  // Set the pitch, lang, volume
  utterance.pitch = 0.95;
  utterance.lang = "en-US";
  utterance.volume = 0.8;

  // Speak the text

  if (shouldCancel) {
    speechSynthesis.cancel();
  }

  setTimeout(() => {
    speechSynthesis.speak(utterance);
  }, 0);
  let r = setInterval(() => {
    if (!speechSynthesis.speaking) {
      clearInterval(r);
    } else {
      speechSynthesis.pause();
      speechSynthesis.resume();
    }
  }, 5000);
}

export function getRandomProcessingMessage() {
  return processingMessages[
    Math.floor(Math.random() * processingMessages.length)
  ];
}

// Initialize S3 with your credentials
AWS.config.update({
  accessKeyId: ACCESS_KEY_ID, // Replace with your actual access key
  secretAccessKey: SECRET_ACCESS_KEY, // Replace with your actual secret key
  region: REGION, // Replace with your S3 bucket's region
  signatureVersion: "v4",
});

const s3 = new AWS.S3();

export async function uploadVideoToS3(
  blob: Blob,
  fileName: string,
  tenantProfileName: string,
  modeType: string
) {
  const params = {
    Bucket: `praxispro-tenant-profiles/${tenantProfileName}/${modeType}`,
    Key: fileName,
    ContentType: blob.type,
    Expires: 60 * 60 * 24 * 3, // URL expiry time in seconds
    ServerSideEncryption: "aws:kms",
  };
  const audioFile = new File([blob], fileName, { type: blob.type });
  try {
    // Step 1: Get signed URL
    const signedUrl = await s3.getSignedUrlPromise("putObject", params);
    // Step 2: Use the signed URL to upload the file
    await fetch(signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": blob.type,
      },
      body: audioFile,
    });
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

export async function uploadAudioToS3(
  blob: Blob,
  fileName: string,
  tenantProfileName: string,
  userId: string,
  sessionId: string
) {
  //s3://{stage}.{tenant}.assets.praxis.com/app/users/{user_id}/calls/{session_id}/{timestamp}_audio.mp3
  const timestamp = Date.now();
  const name = `${timestamp}_audio.webm`;
  const params = {
    Bucket: `sandbox.test.assets.praxis.com/app/users/${userId}/calls/${sessionId}`,
    Key: name,
    ContentType: blob.type,
    Expires: 60 * 60 * 24 * 3, // URL expiry time in seconds
  };
  const audioFile = new File([blob], name, { type: blob.type });
  try {
    // Step 1: Get signed URL
    const signedUrl = await s3.getSignedUrlPromise("putObject", params);
    // Step 2: Use the signed URL to upload the file
    await fetch(signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": blob.type,
      },
      body: audioFile,
    });
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

export async function uploadFullVideoToS3(
  blob: Blob,
  fileName: string,
  tenantProfileName: string,
  userId: string,
  sessionId: string
) {
  // ${s3_bucket_name}/app/users/${userId}/calls/${sessionId}/session-raw-video.mp4
  const params = {
    Bucket: `sandbox.test.assets.praxis.com/app/users/${userId}/calls/${sessionId}`,
    Key: "session-raw-video.webm",
    ContentType: blob.type,
    Expires: 60 * 60 * 24 * 3, // URL expiry time in seconds
    ServerSideEncryption: "aws:kms",
  };
  const audioFile = new File([blob], "session-raw-video.webm", {
    type: blob.type,
  });
  try {
    // Step 1: Get signed URL
    const signedUrl = await s3.getSignedUrlPromise("putObject", params);
    // Step 2: Use the signed URL to upload the file
    await fetch(signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": blob.type,
      },
      body: audioFile,
    });
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

export function secondsToMinute(seconds: number) {
  // 150 seconds = 02:30
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = (seconds % 60).toFixed(0);
  return `${minutes}:${remainingSeconds}`;
}
