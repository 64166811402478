export function Card({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`bg-white shadow-[0px_4px_24px_0px_rgba(203,90,90,0.05)] px-6 py-4 rounded-[14px] ${className}`}
    >
      {children}
    </div>
  );
}
