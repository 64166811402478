const tabs = [
  {
    label: "Performance",
    value: "performance",
  },
  {
    label: "AI Feedback",
    value: "ai-feedback",
  },
  {
    label: "Session Recap",
    value: "session-recap",
  },
];

export function Tabs({ value, onChange }) {
  return (
    <div>
      {tabs.map((tab) => (
        <button
          key={tab.value}
          onClick={() => onChange(tab.value)}
          className={`report-tab-button relative text-base p-2 md:p-2.5 ${
            value === tab.value
              ? "text-primary selected-report-tab-button font-semibold"
              : "text-black hover:text-black/70 font-normal"
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}
