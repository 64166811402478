export const performanceColors = {
  low: {
    progressBg: "bg-[#FFEBEB]",
    progress: "bg-[#FA7D7D]",
    text: "text-[#FA7D7D]",
  },
  medium: {
    progressBg: "bg-[#FCC67524]",
    progress: "bg-[#FCC675]",
    text: "text-[#FCC675]",
  },
  high: {
    progressBg: "bg-[#E1EDE2]",
    progress: "bg-[#14AE5C]",
    text: "text-[#14AE5C]",
  },
};

export function PerformanceView({
  title,
  value,
}: {
  title: string;
  value: number;
}) {
  let color = performanceColors.low;
  if (value > 40) {
    color = performanceColors.medium;
  }
  if (value > 70) {
    color = performanceColors.high;
  }
  return (
    <div>
      <div className="flex items-center justify-between flex-wrap gap-2">
        <div className="text-[#1E1E1E] text-lg">{title}</div>
        <div className={`hidden md:block ${color.text} text-base font-bold`}>
          {value}%
        </div>
      </div>
      <div className="flex items-center gap-2 mt-2.5">
        <div
          className={`flex-1 flex-1 h-[9px] overflow-hidden rounded-full ${color.progressBg}`}
        >
          <div
            className={`h-[9px] rounded-full ${color.progress}`}
            style={{
              width: `${value || 0}%`,
            }}
          />
        </div>
        <div className={`md:hidden ${color.text} text-base font-bold`}>
          {value}%
        </div>
      </div>
    </div>
  );
}
