import { useEffect, useRef } from "react";
import { RealtimeTranscriber } from "assemblyai";

const BUFFER_TIMEOUT = 1500;
const SAMPLE_RATE = 16_000;
const END_UTTERANCE_SILENCE_THRESHOLD = 1000;

export const useAssemblyAI = ({
  token,
  onConnect,
  onTranscript,
}: {
  token?: string | null;
  onConnect: (realtimeTranscriber: any) => void;
  onTranscript: (transcript: any) => void;
}) => {
  /** @type {React.MutableRefObject<RealtimeTranscriber>} */
  const realtimeTranscriber = useRef<RealtimeTranscriber | null>(null);
  const buffer = useRef<string>("");
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const addTranscript = (text: string) => {
    buffer.current += text;
    resetTimeout();
  };

  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      flushBuffer();
    }, BUFFER_TIMEOUT);
  };

  const flushBuffer = () => {
    if (buffer.current.length > 0) {
      console.log("\nBuffered Transcript:", buffer.current);
      onTranscript(buffer.current);
      buffer.current = "";
    }
    timeoutId.current = null;
  };

  const connect = async () => {
    if (realtimeTranscriber.current) {
      await realtimeTranscriber.current.connect();
    }
  };

  useEffect(() => {
    if (token && !realtimeTranscriber.current) {
      realtimeTranscriber.current = new RealtimeTranscriber({
        token,
        sampleRate: SAMPLE_RATE,
        disablePartialTranscripts: false,
        endUtteranceSilenceThreshold: END_UTTERANCE_SILENCE_THRESHOLD,
      });
      realtimeTranscriber.current.on("open", ({ sessionId, expiresAt }) => {
        onConnect(realtimeTranscriber);
      });
      realtimeTranscriber.current.on("close", (code: number, reason: string) =>
        console.log("Closed", code, reason)
      );
      realtimeTranscriber.current.on("transcript", (transcript: any) => {
        // onTranscript(transcript);
        if (!transcript.text) {
          return;
        }
        if (transcript.message_type === "PartialTranscript") {
          resetTimeout();
        } else {
          console.log("Final:", transcript.text);
          addTranscript(transcript.text);
        }
      });
      realtimeTranscriber.current.on("transcript.final", (transcript: any) => {
        console.log("blob Final transcript:", transcript);
      });
      realtimeTranscriber.current.on("error", (error: Error) =>
        console.error("Error", error)
      );
      connect();
    }
  }, [token]);

  return {
    realtimeTranscriber: realtimeTranscriber.current,
  };
};
