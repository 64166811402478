// This component is responsible for playing the audio files and speaking the transcripts one by one from the given list of media files and transcripts.
// first, we will create a state to keep track of the current index of the media files and transcripts.
// We will also create a state to keep track of the current audio element.
// We will create a function to play the next audio file and transcript.
// first it will play first audio file and then speak the first transcript and so on.
// We will also create a function to stop the audio and speech synthesis.
// it should automatically play the next audio file and transcript when the current audio file ends.
// next audio file should be played only when the transcript is finished speaking.

import React, { useState, useEffect } from "react";
import { Button } from "../../components/Button";
import { ReactComponent as CallIcon } from "../../assets/icons/headphone.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import { Bars } from "react-loader-spinner";
import type { Conversation } from "./ReportScreen";

function AudioPlayerView({
  onClose,
  onPause,
  onResume,
  isPaused,
}: {
  onClose: () => void;
  onPause: () => void;
  onResume: () => void;
  isPaused: boolean;
}) {
  return (
    <div
      className="shadow-[0px_2.59px_17.36px_0px_#6565654A] px-3.5 py-[18px] rounded-full flex items-center gap-4 fixed bottom-6 right-6"
      style={{
        backdropFilter: "blur(5.183154582977295px)",
      }}
    >
      <div className="w-[60px] h-[60px] rounded-full bg-[#F1E4E5] flex items-center justify-center">
        <Bars
          height="28"
          width="28"
          color="#7C2928"
          ariaLabel="bars-loading"
          visible={true}
        />
      </div>
      <div className="flex-1 pr-5">
        <div>Session Replay</div>
        <div>Monday, September 3, 2024</div>
      </div>
      <div className="pr-4">
        <button onClick={isPaused ? onResume : onPause}>
          {isPaused ? (
            <PlayIcon className="w-11 h-11" />
          ) : (
            <PauseIcon className="w-11 h-11" />
          )}
        </button>
      </div>
      <button
        onClick={onClose}
        className="w-8 h-8 rounded-full bg-[#F4C9C2] flex items-center justify-center absolute -top-3 right-8"
      >
        <CloseIcon className="w-5 h-5" />
      </button>
    </div>
  );
}

const CallAudio = ({
  conversations = [],
  currentIndex,
  setCurrentIndex,
}: {
  conversations?: Conversation[];
  currentIndex?: number;
  setCurrentIndex: any;
}) => {
  const [currentAudio, setCurrentAudio] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isConversationStarted, setIsConversationStarted] = useState(false);
  const speechRef = React.useRef<SpeechSynthesisUtterance | null>(null);
  const speechIntervalRef = React.useRef<any>();

  const resetAudio = () => {
    setCurrentIndex(undefined);
    setCurrentAudio(null);
    setIsPlaying(false);
    setIsConversationStarted(false);
  };

  const play = () => {
    const isPaused = isConversationStarted && !isPlaying;
    if (currentIndex === undefined) {
      setCurrentIndex(0);
    }
    const conversation = conversations[currentIndex || 0];
    if (!conversation) {
      resetAudio();
      return;
    }
    // if (conversation?.session_url) {
    //   const audio = new Audio(conversation.session_url);
    //   setCurrentAudio(audio);
    //   audio.play();

    //   audio.onended = () => {
    //     if ((currentIndex || 0) === conversations.length - 1) {
    //       resetAudio();
    //       return;
    //     }
    //     setCurrentIndex((currentIndex || 0) + 1);
    //   };
    // } else {
    if (!speechRef.current) {
      speechRef.current = new SpeechSynthesisUtterance(conversation.message);
      const voices = speechSynthesis.getVoices();

      const voice = voices.find(
        (voice) => voice.name === "Google UK English Female"
      );
      if (voice) {
        speechRef.current.voice = voice;
      }
      speechRef.current.pitch = 0.95;
      speechRef.current.lang = "en-US";
      speechRef.current.volume = 0.8;
    } else {
      speechRef.current.text = conversation.message;
    }
    if (!isPaused) {
      speechSynthesis.speak(speechRef.current);
    }

    speechRef.current.onend = () => {
      if ((currentIndex || 0) === conversations.length - 1) {
        resetAudio();
        return;
      }
      setCurrentIndex((currentIndex || 0) + 1);
    };

    speechIntervalRef.current = setInterval(() => {
      if (speechSynthesis.speaking && !speechSynthesis.paused) {
        speechSynthesis.pause();
        speechSynthesis.resume();
      } else if (!speechSynthesis.speaking) {
        clearInterval(speechIntervalRef.current);
      }
    }, 5000);
    // }
    if (!currentIndex && !isConversationStarted) {
      setIsConversationStarted(true);
    }
    setIsPlaying(true);
  };

  const pause = () => {
    const conversation = conversations[currentIndex || 0];
    const isSpeechSynthesis = !conversation.session_url;
    // if (!isSpeechSynthesis) {
    //   currentAudio.pause();
    // } else {
    speechSynthesis.pause();
    if (speechIntervalRef.current) {
      clearInterval(speechIntervalRef.current);
      speechIntervalRef.current = null;
    }
    // }
    setIsPlaying(false);
  };

  const resume = () => {
    const conversation = conversations[currentIndex || 0];
    // const isSpeechSynthesis = !conversation.session_url;
    // if (!isSpeechSynthesis) {
    //   currentAudio.play();
    // } else {
    speechSynthesis.resume();
    // Only start the interval if it's not already running
    if (!speechIntervalRef.current) {
      speechIntervalRef.current = setInterval(() => {
        if (speechSynthesis.speaking && !speechSynthesis.paused) {
          speechSynthesis.pause();
          speechSynthesis.resume();
        } else if (!speechSynthesis.speaking) {
          clearInterval(speechIntervalRef.current);
          speechIntervalRef.current = null;
        }
      }, 5000);
    }
    // }
    setIsPlaying(true);
  };

  useEffect(() => {
    // on index change
    if (currentIndex) {
      // play after 2 seconds
      setTimeout(() => {
        play();
      }, 700);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (!speechRef.current) {
      speechRef.current = new SpeechSynthesisUtterance("");
      const voices = speechSynthesis.getVoices();

      const voice = voices.find(
        (voice) => voice.name === "Google UK English Female"
      );
      if (voice) {
        speechRef.current.voice = voice;
      }
      speechRef.current.pitch = 0.95;
      speechRef.current.lang = "en-US";
      speechRef.current.volume = 0.8;
      speechSynthesis.speak(speechRef.current);
    }
  }, []);

  return (
    <div>
      {!isConversationStarted ? (
        <Button
          variant="primary"
          className="!rounded-full !py-3 !px-10 w-full md:w-fit"
          onClick={play}
        >
          <CallIcon className="w-[19px] h-[19px]" />
          Listen to session
        </Button>
      ) : (
        <>
          <Button
            variant="primary"
            className="!rounded-full !py-3 !px-10 w-full md:w-fit"
            onClick={!isPlaying ? resume : pause}
          >
            <CallIcon className="w-[19px] h-[19px]" />
            {!isPlaying ? "Resume" : "Pause"}
          </Button>
        </>
      )}
      {isConversationStarted && (
        <AudioPlayerView
          onClose={() => {
            speechSynthesis.cancel();
            resetAudio();
          }}
          onPause={() => pause()}
          onResume={() => resume()}
          isPaused={isConversationStarted && !isPlaying}
        />
      )}
    </div>
  );
};

export default CallAudio;
