import { useState } from "react";
import { Button } from "../../components/Button";
import { usePraxisCall } from "../praxis-play/context/praxiscall-context";
import { FCRReport } from "./FCRReport";
import { Tabs } from "./components/Tabs";
import { Performance } from "./components/report-tabs/Performance";
import { AIFeedback } from "./components/report-tabs/AIFeedback";
import { SessionRecap } from "./components/report-tabs/SessionRecap";
import CallAudio from "./CallAudio";

export type Conversation = {
  id: string;
  message: string;
  isUserMessage: boolean;
  session_url?: string | null;
};

type Report = {
  conversations: Conversation[];
  languageClarity: number;
  vocabulary: number;
  brandStrategy: number;
  questionTechniques: number;
  compliancePercentage: number;
  tone: number;
};

export function ReportsView() {
  const { setCallStatus, report } = usePraxisCall() as {
    setCallStatus: React.Dispatch<React.SetStateAction<string>>;
    report: Report;
  };
  const [tab, setTab] = useState("performance");
  const [showFcrReport, setShowFcrReport] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(
    undefined
  );

  return (
    <div className="bg-[#F3F1F2] p-4 pt-20 lg:p-8">
      {!showFcrReport ? (
        <>
          <div className="md:mb-8 flex items-center justify-between">
            <div>
              <div className="text-black text-2xl font-bold">
                Praxis Play Feedback
              </div>
              <div className="text-black text-lg mt-3">
                View your AI generated feedback for you last Praxis Play session
              </div>
            </div>
            <Button
              variant="primary"
              className="hidden md:block !rounded-full !py-3 !px-10"
              onClick={() => setCallStatus("idle")}
            >
              Call Again
            </Button>
          </div>
          <Button
            variant="primary"
            className="md:hidden my-5 w-full !rounded-full !py-3 !px-10"
            onClick={() => setCallStatus("idle")}
          >
            Call Again
          </Button>
          <div className="mb-5">
            <div className="relative border-b-2 border-[#D5D5D5] my-4">
              <Tabs value={tab} onChange={setTab} />
              <div className="absolute bottom-2 right-0">
                <CallAudio
                  conversations={report.conversations || []}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                />
              </div>
            </div>
            {tab === "performance" && <Performance report={report} />}
            {tab === "ai-feedback" && <AIFeedback report={report} />}
            {tab === "session-recap" && (
              <SessionRecap
                report={report}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
              />
            )}
            {/* <ReportOverview
              report={report}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            /> */}
          </div>
        </>
      ) : (
        <FCRReport />
      )}
    </div>
  );
}
