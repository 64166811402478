import axios, { AxiosError, AxiosResponse } from "axios";
import { BACKEND_URL } from "../env";
import { getTenantKey } from "./utils";

export const buildUrl = (url: string) => {
  if (BACKEND_URL.includes("/tenant")) {
    return `${BACKEND_URL}${url}`;
  }
  const domain = getTenantKey();
  const firstPart = domain.split(".")[0];
  // if it has -dev then remove it
  const tenant = firstPart.replace("-dev", "");
  // TODO: @deprecated - will be removed once it's not needed
  // const hash = {
  //   goodpharma: "goodpharma",
  //   digitalpharma: "digitalpharma",
  //   neurocrine: "neurocrine",
  //   bms: "bms",
  //   "sunpharma-dev": "sunpharma",
  //   "alnylam-dev": "alnylam",
  // };
  return `${BACKEND_URL}tenant/${tenant}/${url}`;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.replace("/login");
  }
  return response;
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return `Bearer ${token}`;
  }
  return null;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  if (error.response?.status === 401) {
    localStorage.removeItem("token");
    // toast.error('Session expired. Please login again.');
    if (window.location.pathname !== "/login") {
      window.location.replace("/login");
    }
  }
  return Promise.reject(error);
};

const apiClient = axios.create({
  baseURL: buildUrl(""),
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

apiClient.interceptors.request.use(
  (config: any) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(onResponse, onResponseError);

export default apiClient;
